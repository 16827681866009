*{
  /* font-size: 14px !important; */
  /* line-height: 1.6rem !important; */
}

body {
  margin: 0;
  font-family: "Open sans", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  padding: 0 !important;
  background-color: #FDFAF2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sticky-div {
  position: sticky;
  z-index: 9;
  top: 0; /* Stick to the top of the scrolling container */
  /* You can also use 'bottom: 0;' to stick it to the bottom */
  
  /* Additional styling */
  background-color: #ffffff; /* Background color */
  border: 1px solid #cccccc; /* Border */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Shadow for a little depth */
}

.d-inline-block {
  display: inline-block;
}
.w-full {
  width: 100%;
}
.d-none {
  display: none;
}
@media (min-width: 420px) and (max-width: 767px) {
  .custom-tab-d-none {
    display: none;
  }
  .custom-tab-d-inline-block {
    display: inline-block;
  }
}
@media (min-width: 768px) {
  .custom-md-d-none {
    display: none;
  }
  .custom-md-d-inline-block {
    display: inline-block;
  }
}
@media (min-width: 1024px) {
  .custom-lg-d-none {
    display: none;
  }
  .custom-lg-d-inline-block {
    display: inline-block;
  }
}