:root{
    --sd-rating-good-color: #F8D107 !important;
  }
  .sd-root-modern,
  .sd-input{
    background-color: #fff !important;
  }
  .sd-btn--action{
    background-color: #00D8C5 !important;
  }
  .sd-rating__item-star--selected svg{
    fill: #F8D107 !important;
    stroke: #F8D107 !important;
  }
  .sd-rating__item-star:focus-within svg{
    stroke: #F8D107 !important;
  }
  .sd-element--with-frame{
    box-shadow: none !important;
    border-radius: 0px !important;
    padding: 0px !important;
    margin-bottom: 10px !important;
  }
  .sd-body.sd-body--static{
    padding-bottom: 0px !important;
  }
  .sd-root-modern--mobile .sd-body.sd-body--static{
    padding: 0px !important;
    padding-top: 10px !important;
  }
  .sd-body__navigation .sd-btn{
    padding: 8px 16px !important;
  }
  .sd-element__title span{
    font-size: 14px !important;
  }
  .svc-flex-row.svc-full-container{
    height: 100% !important;
  }
  .svc-creator__content-wrapper{
    height: 100% !important;
  }
  .sd-rating fieldset {gap:6px!important;}
  .sd-rating__item-star {width:45px!important}
  .sd-rating__item-star .sv-star{
    width: 35px !important;
  }
  .sd-rating__item-star:not(:first-of-type) {
    padding-left: 0px !important;
  }
  .sd-rating__item-star:not(:last-of-type){
    padding-right: 0px !important;
  }
  .sd-title.sd-element__title{
    display: none !important;
  }
  .sd-rating__item-star svg {width:30px!important;}
  .sd-completedpage > h3{
    font-size: 16px !important;
  }
  .sv-action{
    width: 100% !important;
  }
  .sd-page {
    padding: 0 !important;
  }
  .sd-body.sd-body--static .sd-body__navigation.sd-action-bar {
    padding: 20px 0 10px 0 !important;
  }
  .sd-body.sd-body--static{
    padding-top: 0px !important;
  }
  .sd-body.sd-body--static{
    margin-left: 0px !important;
    margin-right: 0px !important;
    max-width: 100% !important;
  }
  #sv-nav-complete{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 6px !important;
  }
  .sv-action__content{
    width: 48% !important;
  }
  @media (max-width:650px){
    .sv-action__content{
      width: 100% !important;
      margin-top: 10px !important;
    }
    .remind-btn {
      width: 100% !important;
    }
  }
  .sd-btn--action{
    background-color: #2563EB !important;
  }
  .sd-input:focus{
    box-shadow: 1px 1px 1px #94A3B8;
  }
  .sd-input{
    border: 1px solid #94A3B8;
  }
  .sd-completedpage:before {
    height: 0px !important;
  }
  .sd-element--with-frame>.sd-element__erbox{
    margin-left: 0px !important;
    margin-right: 0px !important;
    width: 100% !important;
  }
  .sd-rating__item-star--selected:focus-within .sv-star{
    opacity: 1 !important;
  }
  .sd-question__header--location-top{
    padding-bottom: 8px !important;
  }
  .sd-description{
    font-size: 14px !important;
    font-weight: 400;
    color: #64748B;
  }
  .sd-row.sd-page__row{
    margin-top: 0px !important;
  }
  .sd-comment {
    font-size: 14px;
    line-height: 18px;
  }
  .sd-completedpage h3{
    line-height: 10px !important;
    font-size: 12px !important;
    display: none !important;
  }
  .overflowY-auto {
    overflow-y: auto !important;
  }
  .d-flex {
    display: flex;
  }
  .radius-mdl {
    border-radius: 1.14rem !important;
  }
  .text-mdl {
    font-size: 1.14rem !important;
  }
  .text-primary600 {
    color: #2563eb !important;
  }
  .text-newGray700 {
    color: #475569 !important;
  }
  .text-newGray800 {
    color: #334155 !important;
  }
  .bg-white {
    background-color: #fff !important;
  }
  .mt-9 {
    margin-top: 2.5rem !important;
  }
  .border-newGray300 {
    border-color: #cbd5e1 !important;
  }
  .border-newGray400 {
    border-color: #B0BED1 !important;
  }
  .border-solid {
    border-style: solid;
  }
  .bg-none {
    background-color: transparent !important;
  }
  .btn {
    cursor: pointer;
    font-family: inherit;
    padding: .5rem 1rem;
    border-radius: .3rem;
    display: inline-block;
    line-height: 1.5rem;
  }
  .outline-none {
    outline: none;
  }
  .fw-r {
    font-weight: 400 !important;
  }
  .fw-sb {
    font-weight: 600 !important;
  }
  input {
    width: calc(100% - 14px);
  }